import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';
export const fetchProject = project => useApi(http.get(`/projects/${project}`));

export const fetchStage = (project: string, stage: string) => {
  return useApi(http.get(`/projects/${project}/stages/${stage}`));
};

export const createEnrollments = (
  project: string,
  group: string,
  introduction = '',
) =>
  useApi(
    http.post(`/projects/${project}/enrollments`, { group, introduction }),
  );

export const fetchEnrollments = (project: string, group: string) =>
  useApi(http.get(`/projects/${project}/enrollments`, { params: { group } }));

export const reviewSubmission = (project: string, enrollment, data) => {
  return useApi(
    http.post(`/projects/${project}/enrollments/${enrollment}/review`, data),
  );
};

export const unlockStage = (project: string, stage: string, group: string) => {
  return useApi(
    http.post(`/projects/${project}/stages/${stage}/unlock`, { group }),
  );
};
